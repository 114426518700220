<template>
  <div class="myCourse-content">
    <div class="nav-wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation'}">我的参与</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/participation',query:{type:'course'}}">教学活动</el-breadcrumb-item>
        <el-breadcrumb-item>课程在线考试</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="myCourse-exam">
      <div class="title">
        <div class="avatar">
          <el-avatar :size="36" :src="loadUrl(userIcon)">
            <span class="iconfont icon-touxiang"></span>
          </el-avatar>
          <span>{{ applyName }}</span>
        </div>
        <div class="courseName">
          <span>考试科目：{{ examInfo.courseTitle }}</span>
        </div>
        <div class="count-down">
          <span><i class="el-icon-time"></i>剩余时间</span>
          <span class="time">{{ countDownTime }}</span>
        </div>
      </div>
      <div class="exam-content">
        <div class="left">
          <div class="top">
            <div class="head">
              <h3>答题卡</h3>
              <div class="tabs">
                <div class="tab">
                  <span>已答</span>
                  <div class="square over "></div>
                </div>
                <div class="tab">
                  <span>在答</span>
                  <div class="square now"></div>
                </div>
                <div class="tab">
                  <span>未答</span>
                  <div class="square not-yet"></div>
                </div>
              </div>
            </div>
            <div class="questionList">
              <div class="schedule">
                <div class="progress">
                  <span>答题进度</span>
                  <span>{{ answerTotal }}/{{ questionList.length }}</span>
                </div>
                <el-progress :percentage="precent" :show-text="false"></el-progress>
                <div class="total-message">
                  <span>共{{ questionList.length }}题，满分{{ examInfo.totalScore }}分</span>
                </div>
              </div>
              <h3>答题信息</h3>
              <ul class="questions">
                <li class="item " v-for="(item,index) in questionList" :key="index" @click="getSubject(item.id)" :class="{over:item.isTrue!=-1,now:selectedOption===item.id}">{{ item.subjectNumber }}</li>
              </ul>
            </div>
          </div>

          <div class="btn">
            <button @click='next("finish")'>交卷</button>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <div class="type">
              <div class="column-bar"></div>
              <div class="level">
                <h3>{{ subjectInfo.subjectLv == 1 ? '简单题' : subjectInfo.subjectLv == 2 ? '普通题' : '困难题' }}</h3>
                <div class="score">
                  <span>{{ subjectInfo.subjectType == 1 ? '单选题' : subjectInfo.subjectType == 2 ? '判断题' : '多选题' }}</span>
                  <span>（{{ subjectInfo.source }}分）</span>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="question">{{ subjectInfo.subjectNumber }}、{{ subjectInfo.subjectCenter }}</div>
              <div class="options" v-if="subjectInfo.subjectType == 2">
                <el-radio class="item" label="1" v-model="answer" :disabled="answered">正确</el-radio>
                <el-radio class="item" label="2" v-model="answer" :disabled="answered">错误</el-radio>
              </div>
              <el-checkbox-group v-else-if="subjectInfo.subjectType == 1" :disabled="answered" class="options" v-model="answer" :max="1">
                <el-checkbox class="item" v-for="(item,index) in subjectInfo.optionCenter" :label="item.name" :key="index">
                  {{ item.name }}. {{ item.value }}
                </el-checkbox>
              </el-checkbox-group>

              <el-checkbox-group v-else-if="subjectInfo.subjectType == 3" :disabled="answered" class="options" v-model="answer">
                <el-checkbox class="item" v-for="(item,index) in subjectInfo.optionCenter" :label="item.name" :key="index">
                  {{ item.name }}. {{ item.value }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="btn">
            <button class="prev" @click="prev" v-if="subjectInfo.subjectNumber!=1">上一题</button>
            <button class="next" @click='next("finish")' v-if="subjectInfo.subjectNumber===questionList[questionList.length-1].subjectNumber">交卷</button>
            <button class="next" @click="next" v-if="subjectInfo.subjectNumber!==questionList[questionList.length-1].subjectNumber">下一题</button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="478px" :before-close="handleClose">
      <div class="content" v-if="type==='false'">
        <i class="el-icon-warning-outline"></i>
        <span>该科考试成绩不及格，请前往进行补考（剩余{{ canExamTimes }}次）</span>
      </div>
      <div class="content" v-else>
        <i class="el-icon-circle-check"></i>
        <span>该科考试成绩及格，无需进行补考（剩余{{ canExamTimes }}次）</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
        <el-button type="primary" @click="startExam" v-if="type==='false' && canExamTimes!=0">补 考</el-button>
        <el-button type="primary" @click="goScored" v-else-if="type==='true'">查看成绩</el-button>
     </span>
    </el-dialog>


  </div>

</template>

<script>
import {
  getPaperTime,
  getsubjectByPaperId,
  getsubjectBySubjectId,
  saveAnsewer,
  paperFinish,
  startExam
} from "@/api/exam";

export default {
  data() {
    return {
      examOrderId:this.$route.query.examOrderId,
      applyPrderId:this.$route.query.applyPrderId,
      hasExam:this.$route.query.hasExam,
      applyId: '',
      applyName: '',
      userIcon: '',
      selectedOption: '',
      lastTime: '',
      paperId: '',
      questionList: [{subjectNumber:''}],
      examInfo: {},
      subjectInfo: {},
      answerTotal: 0,
      answer: [],
      answered: false,
      countDownTime: '',
      dialogVisible: false,
      type: '',
      canExamTimes: 0
    }
  },
  computed: {
    precent() {
      return this.answerTotal / this.questionList.length *100
    }
  },
  mounted() {
    this.userIcon = JSON.parse(window.localStorage.getItem('user')).userInfo.headIcon || ''
    this.init()
  },
  methods: {
    init(paperId) {
      if(paperId){
        this.paperId=paperId
      }else {
        this.paperId = this.$route.query.paperId
      }

      let params = {paperId: this.paperId}
      getPaperTime(params).then((res) => {
        if (res.code === 200) {
          this.lastTime = res.data
          this.countDown()
        }
      })
      getsubjectByPaperId(params).then((res) => {
        if (res.code === 200) {
          this.applyName = res.data.applyName
          this.applyId = res.data.applyId
          this.questionList = res.data.subjects
          this.examInfo = res.data.examConfigVo
          //排序
          this.questionList.sort((a, b) => {
            return (a.subjectNumber - b.subjectNumber)
          })

          let id = this.questionList[0].id
          this.getSubject(id)
        }
      })
    },
    nextInit() {
      let params = {paperId: this.paperId}
      getsubjectByPaperId(params).then((res) => {
        if (res.code === 200) {
          this.questionList = res.data.subjects
          this.examInfo = res.data.examConfigVo
          //排序
          this.questionList.sort((a, b) => {
            return (a.subjectNumber - b.subjectNumber)
          })
          //计算已经完成多少题 和 当前做到哪一题
          // let now = []
          this.answerTotal = 0
          this.questionList.map((item) => {
            if (item.isTrue != -1) {
              this.answerTotal += 1
            }
          })
        }
      })

    },
    getSubject(id) {
      this.selectedOption = id
      getsubjectBySubjectId({subjectId: id}).then((res) => {
        if (res.code === 200) {
          this.subjectInfo = res.data
          //paperAnswer不为空，即已经答题，把之前选择的答案放回去。为空则answer按照类型设置为空
          if (this.subjectInfo.paperAnswer) {
            this.answered = true
            if (this.subjectInfo.subjectType == 2) {
              this.answer = this.subjectInfo.paperAnswer
            } else if (this.subjectInfo.subjectType == 1) {
              let arr = [this.subjectInfo.paperAnswer]
              this.answer = arr
            } else if (this.subjectInfo.subjectType == 3) {
              this.answer = this.subjectInfo.paperAnswer.split(',')
            }
          } else {
            this.answered = false
            if (this.subjectInfo.subjectType == 2) {
              this.answer = ''
            } else {
              this.answer = []
            }
          }

          this.nextInit()
        }
      })
    },
    next(type) {
      //paperAnswer不为空则直接或许下一题详情，为空则保存当前答案再获取下一题
      if (this.subjectInfo.paperAnswer && type !== 'finish') {
        this.getSubject(this.subjectInfo.next_subjectId)
      } else {
        if(this.answer.length<=0){
          return this.$message({type: 'warning', message: '请先选择答案！'})
        }
        if (this.subjectInfo.subjectType == 1) {
          this.answer = this.answer[0]
        } else if (this.subjectInfo.subjectType == 3) {
          this.answer = this.answer.join(',')
        }
        let params = {
          answer: this.answer,
          subjectId: this.subjectInfo.id
        }
        saveAnsewer(params).then((res) => {
          if (res.code === 200) {
            if (type === 'finish') {
              paperFinish({paperId: this.paperId}).then((res) => {
                if (res.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '交卷成功'
                  })
                  this.dialogVisible = true
                  this.type = res.data.ispass
                  this.canExamTimes = res.data.canExamTimes
                }
              })
            } else {
              this.getSubject(this.subjectInfo.next_subjectId)
            }
          }
        })
      }

    },
    prev() {
      this.getSubject(this.subjectInfo.last_subjectId)
    },
    //倒计时
    countDownFun() {
      let result = parseInt(this.lastTime) //计算出豪秒
      let h = parseInt(result / (60 * 60) % 24)
      h = h < 10 ? "0" + h : h
      let m = parseInt((result / 60) % 60) //剩余分钟就是用1小时等于60分钟进行趣余
      m = m < 10 ? "0" + m : m
      let s = parseInt(result % 60)
      s = s < 10 ? "0" + s : s
      this.countDownTime = h + ':' + m + ':' + s

    },
    //定时器
    countDown() {
      let timer = setInterval(() => {
        this.lastTime -= 1
        if (this.lastTime == 0) {
          clearInterval(timer)
          paperFinish({paperId: this.paperId}).then((res) => {
            if (res.code === 200) {
              this.dialogVisible = true
              this.type = res.data.ispass
              this.canExamTimes = res.data.canExamTimes
            }
          })

        } else {
          this.countDownFun()
        }
      }, 1000)
    },
    handleClose() {
      this.$router.push({
            path: '/myCourse-detail',
            query: {
              applyPrderId: this.applyPrderId,
              hasExam: this.hasExam
            }
          }
      )
    },
    goScored() {
      this.$router.push({
        path:'/grades',
        query:{
          applyId:this.applyId,
          applyOrderId:this.applyPrderId
        }
      })
    },
    //补考
    startExam() {
      startExam({examOrderId: this.examOrderId}).then((res) => {
        if (res.code === 200) {
          this.dialogVisible=false
         let paperId = res.data.paperId
          this.init(paperId)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.myCourse-exam {
  .title {
    width: 1200px;
    height: 74px;
    background: #1E50AE;
    border-radius: 6px;
    padding: 18px 20px;
    box-sizing: border-box;
    position: relative;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    font-size: 16px;
    color: #FFFFFF;

    .avatar {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      .el-avatar {
        margin-right: 10px;
      }
    }

    .courseName {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .count-down {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      //width: 238px;
      height: 36px;
      background: #1045A7;
      box-shadow: 0px 0px 13px 0px rgba(10, 2, 4, 0.15);
      border-radius: 6px;
      padding: 8px 14px;
      color: #A9B5D9;
      font-size: 16px;
      display: flex;
      box-sizing: border-box;
      align-items: center;

      .el-icon-time {
        padding-right: 8px;
      }

      .time {
        padding-left: 10px;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }

  .exam-content {
    margin-top: 10px;
    display: flex;

    .left {
      width: 300px;
      background: #FFFFFF;
      border-radius: 6px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .head {
        padding: 26px 20px;
        display: flex;
        justify-content: space-between;

        h3 {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }

        .tabs {
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;

          .tab {
            margin-left: 16px;
            display: flex;
            align-items: center;

            .square {
              display: inline-block;
              width: 12px;
              height: 12px;
              margin-left: 5px;
              border-radius: 2px;
              border: 1px solid #1E50AE;

              &.over {
                background-color: #1E50AE;
              }

              &.not-yet {
                border: 1px solid #CCCCCC;
              }
            }

          }
        }
      }

      .questionList {
        padding: 14px 20px 20px 14px;

        .schedule {
          background: #F7F7F7;
          border-radius: 6px;
          padding: 24px 10px;

          .progress {
            font-size: 14px;
            color: #555555;
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
          }

          .total-message {
            margin-top: 18px;
            font-size: 14px;
            color: #999999;
          }
        }

        h3 {
          font-size: 14px;
          color: #333333;
          margin-top: 32px;
          margin-bottom: 20px;
        }

        .questions {
          display: flex;
          flex-wrap: wrap;


          .item {
            width: 28px;
            height: 28px;
            background-color: #FFFFFF;
            border: 1px solid #CCCCCC;
            border-radius: 2px;
            color: #666666;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 14px;
            margin-bottom: 14px;
            cursor: pointer;

            &.over {
              background-color: #1E50AE;
              color: #fff;
            }

            &.now {
              background-color: #EFF4FD;
              border: 1px solid #1E50AE;
              color: #666666;

            }
          }
        }

      }

      .btn {
        margin-top: 60px;
        margin-bottom: 20px;
        border-top: 1px solid #ECECEC;
        display: flex;
        justify-content: center;
        padding-top: 20px;

        button {
          cursor: pointer;
          width: 142px;
          height: 38px;
          background: #1E50AE;
          border-radius: 6px;
          color: #fff;
        }
      }

    }

    .right {
      width: 890px;
      min-height: 550px;
      background: #FFFFFF;
      border-radius: 6px;
      padding: 20px 20px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .top {
        .type {
          display: flex;
          border-bottom: 1px solid #ECECEC;

          .column-bar {
            margin-right: 6px;
            width: 2px;
            height: 18px;
            background: #1E50AE;
          }

          .level {
            margin-top: -4px;

            h3 {
              font-size: 18px;
              font-weight: bold;
              color: #333333;
              padding-bottom: 12px;
            }

            .score {
              font-size: 14px;
              color: #666666;
              padding-bottom: 14px;
            }
          }
        }

        .content {
          margin-top: 24px;

          .question {
            font-size: 14px;
            color: #555555;
            margin-bottom: 30px;
          }

          .options {
            display: flex;
            flex-direction: column;

            .item {
              margin-bottom: 16px;
            }
          }
        }
      }


      .btn {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid #ECECEC;

        .prev {
          cursor: pointer;
          width: 100px;
          height: 38px;
          background: #FFFFFF;
          border: 1px solid #1E50AE;
          border-radius: 6px;
          color: #1E50AE;
          margin-right: 20px;
        }

        .next {
          width: 100px;
          height: 38px;
          background: #1E50AE;
          border-radius: 6px;
          cursor: pointer;
          color: #fff;

        }
      }
    }
  }


}

.myCourse-content {
  ::v-deep.el-dialog {
    border-radius: 10px;

    .content {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;

      .el-icon-warning-outline {
        color: #FF3F35;
        margin-right: 20px;
        font-size: 20px;
      }

      .el-icon-circle-check {
        color: #1E50AE;
        margin-right: 20px;
        font-size: 20px;
      }
    }
  }

  ::v-deep .el-dialog__body {
    padding: 30px 46px;
  }
}
</style>