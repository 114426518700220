import request from '@/util/request'

//根据applyId查询是否可以考试
export function canExam(params) {
    return request({
        url: '/api/course/exam/ExamInFo',
        method: 'GET',
        params
    })
}

//根据考试examConfigId和applyId创建考试的订单
export function getExamOrder(params) {
    return request({
        url: '/api/course/exam/createExamOrder',
        method: 'GET',
        params
    })
}

//获得剩余时间
export function getPaperTime(params) {
    return request({
        url: '/api/course/exam/getPaperTimeByPaperId',
        method: 'GET',
        params
    })
}

//根据examOrderId开始考试
export function startExam(params) {
    return request({
        url: '/api/course/exam/startExam',
        method: 'GET',
        params
    })
}

//根据试卷id获得题目
export function getsubjectByPaperId(params) {
    return request({
        url: '/api/course/exam/getsubjectByPaperId',
        method: 'GET',
        params
    })
}

//根据题目id获得题目详情
export function getsubjectBySubjectId(params) {
    return request({
        url: '/api/course/exam/getsubjectBySubjectId',
        method: 'GET',
        params
    })
}

//根据题目id保存答案
export function saveAnsewer(data) {
    return request({
        url: '/api/course/exam/saveAnsewer',
        method: 'POST',
        data
    })
}

//交卷接口
export function paperFinish(params) {
    return request({
        url: '/api/course/exam/paperFinish',
        method: 'GET',
        params
    })
}

//考试人员名单
export function getStudentList(applyOrderId,params) {
    return request({
        url: `/api/course/exam/getApplyDataByApplyOrderId/${applyOrderId}`,
        method: 'GET',
        params
    })
}

//后台查询考试结果详情
export function getScored(applyId,params) {
    return request({
        url: `/api/course/exam/resultDetail/${applyId}`,
        method: 'GET',
        params
    })
}